<template>
  <div class="h-screen relative">
    <content-holder
      v-if="canViewReports"
      :title="'Revenue Reconciliation'"
      class="absolute inset-0 p-4 sm:p-6 mb-0"
    >
      <div
        class="text-sm font-medium text-center text-gray-500 border-b border-gray-200"
      >
        <ul class="flex flex-wrap -mb-px">
          <li class="mr-2">
            <button
              @click.prevent="tab = 'platform'"
              class="inline-block p-4 border-b-2 border-transparent rounded-t-lg transition-all duration-150 ease-linear"
              :class="[
                tab === 'platform'
                  ? 'text-blue-800 border-b-2 border-blue-800 font-semibold'
                  : 'hover:text-gray-600 hover:border-gray-300',
              ]"
            >
              Platform
            </button>
          </li>
          <li class="mr-2">
            <button
              @click.prevent="tab = 'bank'"
              class="inline-block p-4 rounded-t-lg transition-all duration-150 ease-linear"
              :class="[
                tab === 'bank'
                  ? 'text-blue-800 border-b-2 border-blue-800 font-semibold'
                  : 'hover:text-gray-600 hover:border-gray-300',
              ]"
              aria-current="page"
            >
              Bank
            </button>
          </li>
        </ul>
      </div>
      <div class="pt-4">
        <bank-reconciliation v-if="tab === 'bank'"></bank-reconciliation>
        <platform-reconciliation
          v-if="tab === 'platform'"
        ></platform-reconciliation>
      </div>
    </content-holder>
    <content-holder
      v-else
      :title="'Revenue Reconciliation'"
      class="absolute inset-0 p-6"
    >
      <unauthorized-access></unauthorized-access>
    </content-holder>

    <!-- <teleport to=".report-modal">
            <report-modal :date="formatDates()" :key="reportName" :reportName="reportName" :reportType="reportType"
                @close-modal="generateReportModal = false" v-show="generateReportModal" class="absolute inset-0 z-50 ">
            </report-modal>
        </teleport> -->
  </div>
</template>

<script>
// import DatePicker from "@/components/DatePicker2.vue";
import ContentHolder from "@/components/ContentHolder.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import BankReconciliation from "@/components/report/reconciliation/BankReconciliation.vue";
import PlatformReconciliation from "@/components/report/reconciliation/PlatformReconciliation.vue";
import { views } from "@/helpers/login_details.js";
export default {
  name: "Reports",
  components: {
    ContentHolder,
    UnauthorizedAccess,
    BankReconciliation,
    PlatformReconciliation,
    // DatePicker,
  },
  data: () => ({
    tab: "platform",
  }),
  async created() {
    try {
      if (views.includes("Reports")) {
        this.canViewReports = true;
      } else {
        this.canViewReports = false;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
