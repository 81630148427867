<template>
  <div>
    <template v-if="!loading">
      <div class="flex flex-col md:flex-row md:items-end md:space-x-3 mb-10">
        <div class="mb-4 md:mb-0">
          <p class="text-gray-700 font-medium">Select payment dates</p>
          <div
            class="text-gray-500 mt-4 flex flex-row items-center justify-start space-x-1 sm:space-x-4 dateRange overflow-hidden sm:overflow-visible"
          >
            <div class="w-1/2 sm:w-auto">
              <p class="text-base text-gray-500">Start date:</p>
              <input
                type="date"
                v-model="paymentSelectedDates.startDate"
                class="w-full bg-white border border-gray-500 px-2 py-1.5 rounded"
              />
            </div>
            <div class="w-1/2 sm:w-auto pr-1 sm:pr-0">
              <p class="text-base text-gray-500">End date:</p>
              <input
                type="date"
                v-model="paymentSelectedDates.endDate"
                class="w-full bg-white border border-gray-500 px-2 py-1.5 rounded"
              />
            </div>
          </div>
        </div>
        <div class="lg:w-1/2 w-full">
          <div class="w-full text-left relative sm:pr-0">
            <label for="revenueItems" class="text-gray-500"> Bank </label>
            <select
              id="revenueItems"
              v-model="bank"
              class="block w-full py-2 text-gray-600 text-base px-3 border border-gray-500 bg-white rounded shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
              name="revenueItems"
              @change="resetPageData"
            >
              <option disabled :value="bank">
                {{ bank || "Select new item" }}
              </option>
              <option v-for="item in banks" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        v-if="result.length < 1"
        class="items-center justify-center w-full flex mt-2"
        @drop.prevent="readUploadedData"
        @dragover.prevent
        @dragenter.prevent
      >
        <label
          for="dropzone-file"
          class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span class="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p class="text-xs text-gray-500 dark:text-gray-400">
              SVG, PNG, JPG or GIF (MAX. 800x400px)
            </p>
          </div>
          <input
            @change="readUploadedData"
            id="dropzone-file"
            ref="dropzoneFile"
            type="file"
            class="hidden"
            accept=".xls,.xlsx"
          />
        </label>
      </div>
      <div v-if="result.length > 0">
        <p class="mt-1.5 mb-4 text-gray-400 text-base font-medium hidden">
          Platform and Bank total matches.
        </p>
        <div
          class="mt-0 mb-4 text-gray-800 font-medium flex md:flex-row flex-col justify-center md:justify-between items-center bg-gray-50 py-3 md:p-3 lg:text-lg rounded"
        >
          <div class="text-center md:text-left">
            <span class="text-gray-500">Platform Total:</span>
            <br class="block lg:hidden" />
            <b>{{ formatAmount(platformTotal) }}</b>
          </div>
          <hr class="w-full block md:hidden my-1 border-gray-100" />
          <div class="text-center md:text-left">
            <span class="text-gray-500">Bank Total:</span>
            <br class="block lg:hidden" />
            <b>{{ formatAmount(bankTotal) }}</b>
          </div>
          <hr class="w-full block md:hidden my-1 border-gray-100" />
          <div class="text-center md:text-left">
            <span class="text-gray-500">Total Due:</span>
            <br class="block lg:hidden" />
            <b>{{ formatAmount(totalDue) }}</b>
          </div>
        </div>
        <div
          class="relative overflow-x-auto overflow-y-scroll sm:rounded-lg border"
          :style="`height: ${result.length > 9 ? '55vh' : '100%'} !important`"
        >
          <table
            id="result"
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-4 py-3">Narration</th>
                <th scope="col" class="px-4 py-3">Payment Date</th>
                <th scope="col" class="px-4 py-3">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(payment, index) in result"
                :key="index"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <th
                  scope="row"
                  class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white max-w-xs lg:max-w-lg"
                >
                  <div
                    style="
                      word-wrap: break-word !important;
                      overflow-x: auto !important;
                    "
                  >
                    {{ payment.Narration || payment.Description }}
                  </div>
                </th>

                <td class="px-4 py-4">
                  <div class="w-36">
                    {{
                      formatExcelDate(payment.payment_date_time || payment.Date)
                    }}
                  </div>
                </td>
                <td class="px-4 py-4">{{ formatAmount(payment.Amount) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        v-if="result.length > 0"
        class="flex sm:justify-between sm:items-center mt-4 pb-10"
      >
        <div class="text-center md:text-left">
          Transactions: <br class="block md:hidden" />
          <b>{{ transactionCount }}</b>
        </div>
        <div>
          <button
            @click.prevent="resetPageData"
            class="font-medium text-red-600 dark:text-red-500 hover:underline sm:mr-6"
          >
            Reset
          </button>
          <button
            type="button"
            @click.prevent="exportRecord"
            :disabled="exporting"
            class="px-8 py-2 w-full sm:w-auto font-semibold text-white text-md transition duration-500 ease-in-out transform rounded-lg shadow-sm bg-gradient-to-r from-green-600 hover:from-green-600 to-green-600 hover:to-green-600 focus:ring focus:ring-green-600 focus:outline-none"
          >
            <div v-if="!exporting">Export</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
        </div>
      </div>
    </template>
    <div v-else class="w-full h-full flex items-center text-center">
      <i
        :class="`lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
      ></i>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import {
  excelToJson,
  excelSerialToDayJs,
  handleExcelExport,
} from "@/helpers/json_export.js";
import { useStore } from "vuex";
import dayjs from "dayjs";
import { banks } from "../../../data/banks";
import Swal from "sweetalert2";
import numeral from "numeral";

const store = useStore();
const bank = ref(banks[0]);
const loading = ref(false);
const exporting = ref(false);
const result = ref([]);
const platformResult = ref([]);
const excelResult = ref([]);
const dropzoneFile = ref(null);

const paymentSelectedDates = reactive({
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
});

// const totalMatches = computed(() => {
//   let bankTotal = result.value.reduce((acc, val) => {
//     return (acc += val.Amount);
//   }, 0);
//   let platformTotal = platformResult.value.reduce((acc, val) => {
//     return (acc += val.amount);
//   }, 0);

//   return platformTotal == bankTotal;
// });
const totalDue = computed(() => {
  return result.value.reduce((acc, val) => {
    return (acc += val.Amount);
  }, 0);
});
const platformTotal = computed(() => {
  return platformResult.value.reduce((acc, val) => {
    return (acc += val.amount);
  }, 0);
});
const bankTotal = computed(() => {
  return excelResult.value.reduce((acc, val) => {
    return (acc += val.Amount);
  }, 0);
});
const transactionCount = computed(() => {
  return result.value.length;
});

function formatAmount(amt) {
  return "₦" + numeral(amt).format("0,0.00");
}
function formatExcelDate(dt) {
  return !isNaN(dt) ? excelSerialToDayJs(dt) : dt;
}
function resetPageData() {
  result.value = [];
}

async function exportRecord() {
  try {
    exporting.value = true;
    await handleExcelExport(
      "Result",
      "Difference",
      `${bank.value}-reconciliation-result`
    );
  } catch (error) {
    console.log(error);
  } finally {
    exporting.value = false;
  }
}
async function readUploadedData(event) {
  try {
    event.preventDefault();
    loading.value = true;
    const file = event.dataTransfer
      ? event.dataTransfer.files[0]
      : event.target.files[0];
    if (!file) return;

    const jsonData = await excelToJson(file);
    await runAnalysis(jsonData.data);
  } catch (error) {
    console.log(error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
    loading.value = false;
  }
}

async function arrayDifference(arr1, arr2) {
  const set2 = new Set(
    arr2.map(
      (item) =>
        `${item.amount}-${dayjs(item.payment_date_time).format("YYYY-MM-DD")}`
    )
  ); // Convert arr2 to a Set with unique keys

  // for (let index = 0; index < arr1.length; index++) {
  //   console.log(
  //     `${arr1[index].Amount}-${excelSerialToDayJs(arr1[index].Date)}`,
  //     arr1[index].Date
  //   );
  // }

  let res = arr1.filter((item) => {
    // console.log(
    //   set2,
    //   `${item.Amount}-${excelSerialToDayJs(item.Date)}`,
    //   set2.has(`${item.Amount}-${excelSerialToDayJs(item.Date)}`)
    // );

    return (
      !set2.has(`${item.Amount}-${excelSerialToDayJs(item.TranDate)}`) &&
      !set2.has(`${item.Amount}-${excelSerialToDayJs(item.Date)}`)
    );
  });

  return res;
}

async function runAnalysis(excelData) {
  try {
    const response = await store.dispatch("fetchItemsByFilter", {
      item: bank.value,
      filter: "Bank",
      startDate: paymentSelectedDates.startDate,
      endDate: paymentSelectedDates.endDate,
    });

    platformResult.value = response;
    excelResult.value = excelData;
    result.value = await arrayDifference(excelData, response);
  } catch (error) {
    console.log(error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
    loading.value = false;
  } finally {
    loading.value = false;
  }
}
</script>

<style lang="scss" scoped></style>
